import React from 'react'
// import { Route,Routes } from 'react-router-dom'
import Home from './component/Home'

const App = () => {
  return (
    <>
      <Home />
    </>
  )
}

export default App