import React from 'react'
import { Link } from 'react-scroll'

const Footer = () => {
    return (
        <>
            <div className='container-fluid footer'>
                <div className='row'>
                    <div className='col-md-8 col-8 footer-banner'>
                         <img   src={window.location.href +"/images/calender1.svg"}  alt="" className='footerImgSize'/>

                        <h4>To Book A Consult, Contact Us</h4>
                            </div>
                            <div  className="col-md-4 col-4 button-talk">

                       <a href='https://calendly.com/onlineable/30min' target='_blank'><button>Let’s Talk</button></a> 
                    </div>
                    <div className='col-md-12  text-center footer-txt' style={{"paddingTop":"1.5rem"}}>
                        <p className='text-light'></p>
                        <p className='text-light'> Privacy Policy  |  Terms And Conditions  | <Link  href="https://google.com" className='footerLink'  to="contact" spy={true} smooth={true} offset={50} duration={100}>Questions? We Love To Hear From You!</Link> </p>
                        <p className='text-light'>© 2022 Onlineable. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer