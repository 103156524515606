import React from 'react'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-scroll';


const Navbar = () => {
    function submitFun(e) {
        e.preventDefault();
    }

    return (
        <>
            <nav className="navbar navbar-expand-sm navbar-light ">
                <div className="container mt-3">
                    <img alt='layer' className='layer' src={window.location.href + '/images/toplayer2.svg'}  />
                    <img alt='logo' className='logo' src={window.location.href + '/images/logo.svg'}  />
                    <button aria-label="toggle" className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse " id="mynavbar">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item firts dropdown">
                                <NavLink className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Who We Help</NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink className="dropdown-item" to="/dashboard">Link 1</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/dashboard">Link 2</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/dashboard">Link 3</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item  dropdown">
                                <NavLink className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">What We Do</NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink className="dropdown-item" to="/seo">SEO</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/ecommerce">eCommerce</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/webdev">Web Development</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                        <form className="d-flex" onSubmit={submitFun}>
                            <Link href="https://google.com" to="contact" spy={true} smooth={true} offset={50} duration={100} > <button className='header-btn'  >Contact Us</button></Link>
                        </form>
                    </div>
                </div>
            </nav>
            
        </>
    )
}

export default Navbar