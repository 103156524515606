import React, { useState } from 'react'
import validator from 'validator'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Contact = () => {
    const [formState, setFormState] = useState({})
    const changeHandler = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
    };        
    function makeCall() {
        window.open('tel:+61 409 645 386', '_parent');
    }
    function submitFun(e) {
        e.preventDefault();
        console.log("data", formState);
        const data = {
            "From": "hello@onlineable.io",
            "To": "pradeeplodhi3001@gmail.com",
            "Subject": "New Contact Received-onlineable",
            "TextBody": `Name: ${formState.name} <br/> Company: ${formState.company} <br/>Email: ${formState.email} <br/> Phone: ${formState.phone} <br/> Message: ${formState.text}`,
            "HtmlBody": `Name: ${formState.name} <br/> Company: ${formState.company} <br/>Email: ${formState.email} <br/> Phone: ${formState.phone} <br/> Message: ${formState.text}`,
            "MessageStream": "outbound"
        }
        if (validator.isEmail(formState.email)) {
            fetch("https://onlineable-email-service.vercel.app/sendemail", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            }).then((result) => {
                result.json().then((resp) => {
                    console.log("done", resp);
                    toast.success("Message sent.", {
                        position: "top-center",
                        autoClose: 500
                    });
                    setFormState({});

                })
            })
        } else {
            toast.error("Please enter correct email.", {
                position: "top-center",
                autoClose: 1000
            });
        }
    }    return (
        <>
            <section >
                <div className='container'>
                    <div className='row form-border'>
                        <div className='col-md-7 '>
                            <form className='form-bg' onSubmit={submitFun}>
                                <div className="row">
                                    <div className="col">
                                        <label for="Name" class="form-label">Name <span className='text-danger' >*</span></label>
                                        <input type="text" value={formState.name || ''} class="form-control" placeholder="Enter Name" name="name" required style={{ "fontSize": "0.8rem" }} onChange={changeHandler} />
                                    </div>
                                    <div className="col">
                                        <label for="Company" class="form-label">Company</label>
                                        <input type="text" value={formState.company || ''} class="form-control" placeholder="Enter Company" name="company" style={{ "fontSize": "0.8rem" }} onChange={changeHandler} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col form-message">
                                        <label for="Phone" class="form-label">Phone</label>
                                        <input type="text" value={formState.phone || ''} class="form-control" placeholder="Enter Phone" name="phone" style={{ "fontSize": "0.8rem" }} onChange={changeHandler} />
                                    </div>
                                    <div className="col form-message">
                                        <label for="email" class="form-label">Email<span className='text-danger'>*</span></label>
                                        <input type="email" value={formState.email || ''} class="form-control" placeholder="Enter Email" name="email" style={{ "fontSize": "0.8rem" }} onChange={changeHandler} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col form-message">
                                        <label for="comment" class="form-label">Message<span className='text-danger'>*</span></label>
                                        <textarea required value={formState.text || ''} class="form-control" rows="3" id="comment" name="text" style={{ "fontSize": "0.8rem" }} onChange={changeHandler}></textarea>
                                    </div>
                                </div>
                                <button type="submit" value='send email' class="btn ">Submit</button>
                            </form>
                        </div>
                        <div className='col-md-5 '>
                            <iframe title="myFrame" className='mapborder' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d33310.97247500912!2d153.58613592779344!3d-28.648198731536432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9062852d3e762b%3A0x40609b49043f2d0!2sByron%20Bay%20NSW%202481%2C%20Australia!5e0!3m2!1sen!2sin!4v1676876153193!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                        </div>
                    </div>
                </div>
                <div className='container blue-margin'>
                    <div className='row'>
                        <div className='col-md-3 col-6'>
                            <div className='blue-circle'>
                                <div className='circle'>
                                    <img src={window.location.href + "/images/Group 1548.svg"} alt="" />
                                </div>
                                <h6>Address</h6>
                                <p>Byron Bay, New South <br />Wales, Australia</p>
                            </div>
                        </div>
                        <div className='col-md-3 col-6'>
                            <a href='#' className='blue-circle' onClick={makeCall}>
                                <div className='circle'>
                                    <img src={window.location.href + "/images/Group 121.svg"} alt="" />
                                </div>
                                <h6>Phone</h6>
                                <p>+61 409 645 386</p>
                            </a>
                        </div>
                        <div className='col-md-3 col-6'>
                            <a href="mailto:hello@onlineable.io" className='blue-circle'>
                                <div className='circle'>
                                    <img src={window.location.href + "/images/Vector.svg"} alt="" />
                                </div>
                                <h6>Email</h6>
                                <p>hello@onlineable.io</p>
                            </a>
                        </div>
                        <div className='col-md-3 col-6'>
                            <a href='https://www.linkedin.com/in/ilonka-hofmann/' target='_blank' className='blue-circle'>
                                <div className='circle'>
                                    <img src={window.location.href + "/images/l2.svg"} alt="" />
                                </div>
                                <h6>linkedin</h6>
                                <p>www.onlineable.io</p>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}

export default Contact